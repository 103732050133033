.icon-cta img,
.icon-cta svg {
  width: 100%;
  height: 100%;
  transform-origin: bottom left;
  @apply transition-transform ease-out;
}

.dashboard-card:hover {
  .icon-cta img,
  .icon-cta svg {
    transform: scale(1.5);
  }
}
